<i18n>
{
  "de": {
    "pageTitle": "Liegenschaft hinzufügen",
    "requiredParamsHint": "Hinweis: Für eine realistische Analyse werden die mit einem Stern (*) gekennzeichneten Angaben benötigt. Weitere detaillierte Angaben können in einem zweiten Schritt ergänzt werden.",
    "saveButton": "Liegenschaft erstellen",
    "addAddressButton": "Weitere Adresse hinzufügen",
    "confirmDeleteAddress": "Wollen Sie diese Adresse wirklich löschen?",
    "identifierTitle": "Liegenschaftsnr.",
    "descriptionTitle": "Bezeichnung",
    "municipalityTitle": "Gemeinde",
    "zipTitle": "Postleitzahl *",
    "buildingYearTitle": "Baujahr Gebäude",
    "buildingYearInfoTitle": "Baujahr Gebäude",
    "renovationYearTitle": "Letzte Sanierung *",
    "renovationYearInfoTitle": "Letzte Sanierung",
    "renovationYearInfoText": "Jahr der letzten umfassenden energetischen Sanierung der Gebäudehülle. Diese Angabe wird für die Einschätzung des Heizwärmebedarfs sowie für die Massnahmenplanung verwendet und ist insbesondere für Gebäude mit Baujahr vor 1990 sehr relevant. Falls genauere Angaben zu Wärmedämmung oder dem Zustand einzelner Bauteile vorhanden sind, können diese nach der erstmaligen Erfassung ergänzt werden.",
    "buildingYearInfoText": "Diese Angabe kann in den meisten Fällen automatisch aus dem Gebäude- und Wohnungsregister ermittelt werden.",
    "heatingTitle": "Wärmeerzeuger *",
    "heatingYearTitle": "Baujahr Heizung",
    "heatingYearInfoTitle": "Baujahr Heizung",
    "heatingYearInfoText": "Das Baujahr der Heizung wird für eine realistische Massnahmenplanung benötigt. Falls unbekannt wird angenommen, dass die Heizung das gleiche Alter wie das Gebäude hat.",
    "heatingInfoTitle": "Heizungstyp",
    "heatingInfoText": "Der Heizungstyp wird für die Modellierung des Energiebedarfs und der Treibhausgasemissionen benötigt. Falls der Heizungstyp nicht bekannt ist, wird von einer Ölheizung ausgegangen. Falls mehrere Wärmeerzeuger vorhandne sind, können diese nach dem erstmaligen Erfassen ergänzt werden.",
    "usageTitle": "Nutzung *",
    "areaTitle": "Fläche *",
    "areaInfoTitle": "Fläche",
    "areaInfoText": "Optimalerweise wird die Energiebezugsfläche (EBF) angegeben. Falls diese nicht bekannt ist, kann sie über die Bruttogeschossfläche (BGF) oder über die Hauptnutzfläche (HNF) hergeleitet werden.",
    "usageInfoTitle": "Nutzung *",
    "usageInfoText": "Die Gebäudenutzung wird für die Abschätzung des Strom-, Warmwasser- und Heizwärmebedarfs benötigt. Falls mehrere Nutzungen vorhanden sind können diese nach dem erstmaligen Erfassen ergänzt werden.",
    "streetTitle": "Strasse/Hausnr. *",
    "pendingMessage": "Die Liegenschaft wird erstellt und GIS-Daten abgefragt. Bitte haben Sie einen Moment Geduld...",
    "areaTypeEbf": "Energiebezugsfläche (EBF)",
    "areaTypeHnf": "Hauptnutzfläche (HNF)",
    "areaTypeBgf": "Bruttogeschossfläche (BGF)",
    "areaTypeVmf": "Vermietbare Fläche (VMF)",
    "confirmSave": "Die Erstellung einer Liegenschaft kann Auswirkungen auf den Preis haben."
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="p-add-building-page">
    <template #default>
      <h1>{{ $t('pageTitle') }}</h1>
      <p v-if="pending">{{ $t('pendingMessage') }}</p>
      <p v-else-if="error">{{ error }}</p>
      <div v-else class="building-form">
        <!-- <hr /> -->

        <FormRow :label="$t('identifierTitle')">
          <TextInput v-model="identifier" :edit="true" :allow-empty="true" lass="limit-width" />
        </FormRow>
        <FormRow :label="$t('descriptionTitle')">
          <TextInput v-model="description" :edit="true" :allow-empty="true" />
        </FormRow>

        <hr />

        <FormRow :label="$t('streetTitle')">
          <DetailList class="address-table">
            <li v-for="(a, index) in addresses" :key="index">
              <TextInput v-model="a.address" :edit="true" :allow-empty="true" />
              <ListButtonWrapper>
                <ListDeleteButton :disabled="addresses.length === 1" @click="onDeleteAddress(index)" />
              </ListButtonWrapper>
            </li>
          </DetailList>
          <ButtonWrapper>
            <button type="button" class="button" @click="onAddAddress">{{ $t('addAddressButton') }}</button>
          </ButtonWrapper>
        </FormRow>
        <FormRow :label="$t('zipTitle')">
          <NumericInput
            v-model="zip_code"
            :edit="true"
            class="limit-width"
            :min="1000"
            :max="9999"
            :optional="true"
            noFormatting
          />
        </FormRow>
        <FormRow :label="$t('municipalityTitle')">
          <TextInput v-model="municipality" :edit="true" :allow-empty="true" />
        </FormRow>

        <hr />

        <FormRow :label="$t('usageTitle')" :info-text="$t('usageInfoText')" :info-title="$t('usageInfoTitle')">
          <ZoneUsagePicker v-model="usage" />
        </FormRow>
        <FormRow :label="$t('areaTitle')" :info-text="$t('areaInfoText')" :info-title="$t('areaInfoTitle')">
          <div>
            <NumericInput v-model="area" optional :edit="true" :units="'m²'" class="limit-width" />
            <div>
              <input id="area-hnf" v-model="areaType" type="radio" value="HNF" />
              <label for="area-hnf">{{ $t('areaTypeHnf') }}</label>
              <br />
              <input id="area-ebf" v-model="areaType" type="radio" value="EBF" selected />
              <label for="area-ebf">{{ $t('areaTypeEbf') }}</label>
              <br />
              <input id="area-bgf" v-model="areaType" type="radio" value="BGF" />
              <label for="area-bgf">{{ $t('areaTypeBgf') }}</label>
              <br />
              <input id="area-vmf" v-model="areaType" type="radio" value="VMF" />
              <label for="area-vmf">{{ $t('areaTypeVmf') }}</label>
            </div>
          </div>
        </FormRow>

        <hr />

        <FormRow :label="$t('heatingTitle')" :info-text="$t('heatingInfoText')" :info-title="$t('heatingInfoTitle')">
          <HeatingTypePicker v-model="heating_type_id" :portfolio="portfolio" :edit="true" />
        </FormRow>
        <FormRow
          :label="$t('buildingYearTitle')"
          :info-text="$t('buildingYearInfoText')"
          :info-title="$t('buildingYearInfoTitle')"
        >
          <NumericInput
            v-model="buildingYear"
            optional
            :edit="true"
            :min="0"
            class="limit-width"
            :max="new Date().getFullYear()"
          />
        </FormRow>
        <FormRow
          :label="$t('renovationYearTitle')"
          :info-text="$t('renovationYearInfoText')"
          :info-title="$t('renovationYearInfoTitle')"
        >
          <NumericInput
            v-model="renovationYear"
            optional
            :edit="true"
            :min="0"
            class="limit-width"
            :max="new Date().getFullYear()"
          />
        </FormRow>
        <FormRow
          :label="$t('heatingYearTitle')"
          :info-text="$t('heatingYearInfoText')"
          :info-title="$t('heatingYearInfoTitle')"
        >
          <NumericInput
            v-model="heatingYear"
            optional
            :edit="true"
            :min="0"
            class="limit-width"
            :max="new Date().getFullYear()"
          />
        </FormRow>

        <p class="required-hint">{{ $t('requiredParamsHint') }}</p>

        <ButtonWrapper>
          <Button :text="$t('saveButton')" @click="onSave" />
        </ButtonWrapper>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import ZoneUsagePicker from '@/components/building/edit/ZoneUsagePicker.vue'
import HeatingTypePicker from '@/components/building/edit/HeatingTypePicker.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import Button from '@/components/cui/inputs/Button.vue'

export default {
  name: 'addBuilding',

  mixins: [
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    ApiFormMixin,
  ],

  components: {
    ListDeleteButton,
    ListButtonWrapper,
    ButtonWrapper,
    MainLayout,
    TextInput,
    NumericInput,
    DetailList,
    ZoneUsagePicker,
    HeatingTypePicker,
    FormRow,
    Button,
  },

  data() {
    return {
      identifier: '',
      description: '',
      municipality: '',
      zip_code: '',
      area: null,
      areaType: 'HNF',
      buildingYear: null,
      renovationYear: null,
      heating_type_id: null,
      heatingYear: null,
      usage: 'RESIDENTIAL',
      addresses: [
        {
          address: '',
        },
      ],
      pending: false,
      error: null,
    }
  },

  // if portfolio is loaded after creation
  watch: {
    portfolioReady(ready) {
      if (ready) {
        this.heating_type_id = 'OIL'
        // this.heating_type_id = this.getOilId()
      }
    },
  },

  // if portfolio is already loaded upon creation
  created() {
    if (this.portfolioReady) {
      this.heating_type_id = 'OIL'
      // this.heating_type_id = this.getOilId()
    }
  },

  methods: {
    ...mapActions({
      addBuilding: 'building/addBuilding',
    }),

    getOilId() {
      const type = this.portfolio.heating_types.find((type) => type.name === 'OIL')
      return type ? type.name : null
    },

    resetModel() {
      this.zip_code = null
    },

    onAddAddress() {
      this.addresses.push({
        address: '',
      })
    },

    onDeleteAddress(index) {
      if (confirm(this.$t('confirmDeleteAddress'))) {
        this.addresses.splice(index, 1)
      }
    },

    async onSave() {
      if (!confirm(this.$t('confirmSave'))) {
        return
      }
      this.pending = true
      try {
        const building = {
          identifier: this.identifier,
          description: this.description,
          municipality: this.municipality,
          zip_code: this.zip_code,
          addresses: this.addresses.map((a) => a.address).join(';'),
          initial_state: {
            envelope: {
              building_year: this.buildingYear ? this.buildingYear : null,
              basement: {
                year: this.renovationYear ? this.renovationYear : null,
              },
              walls: {
                year: this.renovationYear ? this.renovationYear : null,
              },
              windows: {
                year: this.renovationYear ? this.renovationYear : null,
              },
              roof: {
                year: this.renovationYear ? this.renovationYear : null,
              },
            },
            heating: {
              year: this.heatingYear ? this.heatingYear : null,
              heaters: [
                {
                  heating_type_id: this.heating_type_id,
                },
              ],
            },
            zones: {
              zones: [
                {
                  usage: this.usage,
                  energy_area: {
                    manual: this.areaType === 'EBF' ? this.area : null,
                  },
                  floor_area: {
                    manual: this.areaType === 'BGF' ? this.area : null,
                  },
                  rentable_area: {
                    manual: this.areaType === 'VMF' ? this.area : null,
                  },
                  rental_area: this.areaType === 'HNF' ? this.area : null,
                },
              ],
            },
          },
        }
        this.error = null
        const buildingResponse = await this.addBuilding({
          building: building,
          portfolioId: this.portfolio.id,
        })
        this.$router.push({
          name: 'buildingDetails',
          params: { portfolio_id: this.portfolio.id, building_id: buildingResponse.id },
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss">
.p-add-building-page {
  & .building-form {
    max-width: 650px;
  }

  & .detail-list.address-table {
    width: 100%;
  }

  & .detail-list.address-table > ul > li {
    display: grid;
    grid-template-columns: 1fr 50px;
  }

  & .limit-width {
    max-width: 120px;
  }

  & .required-hint {
    margin-bottom: var(--spacing-m);
  }

  & hr {
    margin: var(--spacing-m) 0;
    border: none;
    height: 1px;
    background: #eee;
  }
}
</style>
